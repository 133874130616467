import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import img from "../static/img/login.svg";
import "../static/css/Login.css";

const adminEmail = process.env.REACT_APP_ADMIN_EMAIL;
const adminPassword = process.env.REACT_APP_ADMIN_PASSWORD;

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
    setErrors({ ...errors, [name]: "" });
  };

  const validate = () => {
    let newErrors = {};
    const { email, password } = formData;

    if (!email.trim()) {
      newErrors.email = "Email is required.";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      newErrors.email = "Please enter a valid email address.";
    }

    if (!password.trim()) {
      newErrors.password = "Password is required.";
    } else if (password.length < 8) {
      newErrors.password = "Password must be at least 8 characters long.";
    } else if (!/\d/.test(password)) {
      newErrors.password = "Password must contain at least one number.";
    } else if (!/[!@#$%^&*]/.test(password)) {
      newErrors.password =
        "Password must contain at least one special character.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validate()) {
      const { email, password } = formData;

      if (email === adminEmail && password === adminPassword) {
        navigate("/events");
      } else {
        setErrors({ form: "Invalid email or password." });
      }
    }
  };

  return (
    <>
      <div className="signup">
        <div className="signup-component-1">
          <img src={img} alt="Signup" />
        </div>
        <div className="signup-component-2">
          <div className="signup-content">
            <form onSubmit={handleSubmit} className="signup-form">
              <div className="signup-input">
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
              {errors.email && <span className="error">{errors.email}</span>}

              <div className="signup-input">
                <label htmlFor="password">Password</label>
                <input
                  type="password"
                  id="password"
                  name="password"
                  value={formData.password}
                  onChange={handleChange}
                  placeholder="Password"
                />
              </div>
              {errors.password && (
                <span className="error">{errors.password}</span>
              )}

              {errors.form && <span className="error">{errors.form}</span>}

              <div className="signup-btn">
                <button type="submit">Login</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Signup;
