import React, { useState, useEffect } from "react";
import "../static/css/Events.css";

const Events = () => {
  const [events, setEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const response = await fetch("https://eventbe.quindl.com/api/users");
        const data = await response.json();
        setEvents(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching events data:", error);
        setLoading(false);
      }
    };

    fetchEvents();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="events">
        <div className="events-container">
          <div className="event-dropdown">
            <select name="event_dropdown_select">
              <option>The Synergy Summit</option>
            </select>
          </div>

          <table className="event-table">
            <thead>
              <tr>
                <th>S.No</th>
                <th>Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Role</th>
                <th>About Us</th>
                <th>Organization</th>
                <th>Profession</th>
              </tr>
            </thead>
            <tbody>
              {events.length > 0 ? (
                events.map((event, index) => (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>{event.name}</td>
                    <td>{event.email}</td>
                    <td>{event.phone}</td>
                    <td>{event.role}</td>
                    <td>{event.about_us}</td>
                    <td>{event.organization}</td>
                    <td>{event.profession}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7">No users found</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default Events;
