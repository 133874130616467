import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login.jsx";
import Events from "./pages/Events.jsx";
import "./App.css";

function App() {
  return (
    <>
      <div className="main">
        <div className="main-content">
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="events" element={<Events />} />
            </Routes>
          </Router>
        </div>
      </div>
    </>
  );
}

export default App;
